import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import ActaAuthProvider from './pa_kit/auth';
import AppRoutes from './approutes';
import IMUSettingsProvider from 'services/imu_settings';

import 'css/index.css';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'css/app.css';
import 'css/styles.css';
import 'font-awesome/css/font-awesome.min.css';

import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
		<IMUSettingsProvider>
			<ActaAuthProvider>
				<AppRoutes />
			</ActaAuthProvider>  
		</IMUSettingsProvider>    
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

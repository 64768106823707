/* eslint-disable import/no-anonymous-default-export */
import http from './http';
import { IMUCategoryClass } from 'apps/imu_categories/models/imu_category';
import { ActivityClass } from 'apps/activities/models/activity';


class ApiService {

    //Get IMU Category List
    async getIMUCategoryList() {
        let result = await http.get('imu_categories/');
        return result.data.map((data) => new IMUCategoryClass(data)); 
    }

    //Get IMU Category
    async getIMUCategory(slug) {
        let result = await http.get('imu_categories/' + slug);
        return result.data; 
    }

    //Generate F24
    async generateF24(formData) {
        let result = await http.post('print-f24/', { ...formData });
        return result;
    }

    // Get Activities List
    async getActivities() {        
        let result = await http.get('activities/');       
        return result.data.map((data) => new ActivityClass(data));
    }

    //Create IMU Category
    async createIMUCategory(formData) {
        let result = await http.post('imu_categories/', {...formData });
        return result;
    }

    //Edit IMU Category
    async editIMUCategory(slug, formData) {
        let result = await http.patch('imu_categories/' + slug + '/', {...formData });
        return result;
    }

    //Delete IMU Category
    async deleteIMUCategory(slug) {
        let result = await http.delete('imu_categories/' + slug + '/');
        return result;
    }

    //Get IMU Settings
    async getIMUSettings() {
        let result = await http.get('imu_settings/');
        return result.data[0];
    }

    //Edit IMU Settings
    async editIMUSettings(id, formData) {
        let result = await http.patch('imu_settings/' + id + '/', {...formData });
        return result;
    }
}

export default new ApiService();

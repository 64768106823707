import ActaAuthUtils from "pa_kit/utils";

export class ActaUser {    

    constructor(userData){
        this.id = userData.id;
        this.username = userData.username;
        this.first_name = userData.first_name;
        this.last_name = userData.last_name;
        this.email = userData.email;
        this.is_staff = userData.is_staff;
        this.is_superuser = userData.is_superuser;
        this.groups = userData.groups;
        this.permissions = userData.permissions;

        this.attributes = userData.attributes;

        this.role = this.is_superuser ? "admin" : this.is_staff ? "staff" : "user";

        let selectedRole = ActaAuthUtils.getSelectedRole();
        this.selectedRole = selectedRole ?? (this.hasPermissions() ? undefined : this.role);
    }

    isLoggedIn() {
        return this.username !== undefined;
    }

    isSuperuser() {
        return this.is_superuser;
    }
    
    // isManager() {
    //     return this.is_manager;
    // }


    is(group_name) {
        return this.groups.some(group => group.name === group_name);
    }

    can(permission_codename) {
        return this.permissions.some(permission => permission === permission_codename);
    }



    // DEPRECATA: usa is('operator)
    isOperator() { // Prot. Sportello | Actagov News/Events/Docs | Appointments | Other apps
        //return this.groups.some(group => group.name === 'operator');
        return this.is('operator');
    }

    // DEPRECATA: usa is('editor1)
    isEditor1() { // Actagov Website | Actagov News/Events/Docs | Appointments
        //return this.groups.some(group => group.name === 'editor1');
        return this.is('editor1');
    }

    // DEPRECATA: usa is('editor2)
    isEditor2() { // Actagov News/Events/Docs
        //return this.groups.some(group => group.name === 'editor2');
        return this.is('editor2');
    }

    // DEPRECATA: usa can('xxxxxxx') o is('xxxxx')
    hasPermissions() {
        //return this.isManager() || this.is_superuser;
        let currentRole = (this.selectedRole) ? this.selectedRole : this.role;
        if(currentRole === "admin") return true;
        if(currentRole === "staff") return true;
        return false;
    }



    isActagovWebsiteEditor() { // Can manage everything in the website
        return this.isEditor1() || this.isSuperuser();
    }

    isActagovContentEditor() { // Can manage news, events and documents
        return this.isOperator() || this.isEditor1() || this.isEditor2() || this.isSuperuser();
    }

    getAttribute(key) {
        if(this.attributes[key] !== undefined){
            return this.attributes[key];
        }else{
            return "";
        }
    }
}

export class IMUCategoryClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.codice_tributo_comunale = data.codice_tributo_comunale;
        this.codice_tributo_statale = data.codice_tributo_statale;
        this.aliquota = data.aliquota;
        this.aliquota_acconto = data.aliquota_acconto;
        this.coefficiente_rivalutazione = data.coefficiente_rivalutazione;
        this.moltiplicatore = data.moltiplicatore;
        this.detrazioni = data.detrazioni;
        this.quota_statale = data.quota_statale;
        this.aliquota_statale = data.aliquota_statale;
        this.pertinenze = data.pertinenze;
        this.opzioni_proprietario = data.opzioni_proprietario;
        this.riduzioni = data.riduzioni;
    }    
}
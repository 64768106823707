/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ActaAuthContext } from "./pa_kit/auth";
import App from './app';

const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);

    const user = auth.user;

    const PageIMU = lazy(() => import('apps/pages/components/page_imu'));

    const Dashboard = lazy(() => import('admin/dashboard/dashboard'));
    const Main = lazy(() => import('admin/dashboard/main'));
    const ActivitiesList = lazy(() => import('admin/activities/activities_list'));
    const IMUCategoriesList = lazy(() => import('admin/imu_categories/imu_categories_list'));
    const CreateIMUCategory = lazy(() => import('admin/imu_categories/create_imu_category'));
    const EditIMUCategory = lazy(() => import('admin/imu_categories/edit_imu_category'));
    const IMUSettings = lazy(() => import('admin/imu_settings/imu_settings'));

    if(auth.isLoading) return <div></div>;
    return <>
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path="/" element={<App />} >
                        <Route path="" element={<PageIMU />} ></Route>                                                           
                    </Route>

                    <Route path="/admin" element={user && user.hasPermissions() && !user.isEditor2() ? <Dashboard /> : <Navigate to="/" />} >
                        <Route path="" element={<Main />}></Route>
                        <Route path="imu-categories/" element={<IMUCategoriesList />}></Route>
                        <Route path="create-imu-category" element={<CreateIMUCategory />}></Route>
                        <Route path="edit-imu-category/:categorySlug" element={<EditIMUCategory />}></Route>
                        <Route path="imu-settings/" element={<IMUSettings />}></Route>
                        <Route path="activities/" element={<ActivitiesList />}></Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </>;
}

export default AppRoutes;

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import ApiService from "./api"; 

const IMUSettingsContext = createContext({});

export default function IMUSettingsProvider({ children }) {    

    const [ IMUSettings, setIMUSettings ] = useState();
    const [ isIMUSettingsLoading, setIsIMUSettingsLoading ] = useState(true);

    const fetchIMUSettings = async() => {
        const settings = await ApiService.getIMUSettings();
        setIMUSettings(settings); 
        setIsIMUSettingsLoading(false);
    }

    useEffect(() => {        
        fetchIMUSettings();
    }, []);

    const settings = {        
        IMUSettings,
        isIMUSettingsLoading,
    }

    return (
        <IMUSettingsContext.Provider value={settings}>
            {children}
        </IMUSettingsContext.Provider>
    );
}

export { IMUSettingsContext };

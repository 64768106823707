/* eslint-disable array-callback-return */
// @ts-nocheck
import React from 'react';
import { Container } from 'design-react-kit';
import { Outlet} from 'react-router-dom';
import TopHeader from './pa_kit/components/top_header';
import Footer from './pa_kit/components/footer';


const App = () => {    

    return <>
        <div className="App">
            <TopHeader />
            <Container fluid className="p-0">
                <Outlet />
            </Container>
            <Footer />
        </div>
    </>;
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
// @ts-nocheck
import React, { useContext, useState } from "react";
import { Headers, Header, HeaderContent, HeaderBrand, HeaderRightZone, HeaderSocialsZone, Icon, Collapse, HeaderToggler, Nav, NavLink, NavItem, Row, Col, Modal , ModalHeader, ModalBody, ModalFooter, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, LinkList, LinkListItem, Button } from "design-react-kit";
import { ActaAuthContext } from "pa_kit/auth";
import { useEffect } from "react";
import authApiService from "pa_kit/services/api";
import ApiService from "../../services/api";
import { MenuClass } from "pa_kit/apps/menus/models/menu";
import { useNavigate } from 'react-router-dom';
import ActaAuthUtils from 'pa_kit/utils';
import env from '../../env';
import '../css/styles.css';

const SearchModal = () => {

    const [isOpen, toggleModal] = useState(false);
    const [size, setSize] = useState('');

    const navigate = useNavigate();
    const [ searchKey, setSearchKey ] = useState("");

    const [isFocused, toggleFocus] = useState(false);

    const toggleFocusLabel = () => toggleFocus(true);
    const toggleBlurLabel = (e) => {
        if (e.target.value === '') {
            toggleFocus(!isFocused);
        }
    };

    const toSearch = () => {
        navigate('/ricerca/',{state: { key: searchKey } });
    }
  
    return (
      <div>
        <div>
            <div className="it-search-wrapper">
                <span className="d-none d-md-block">Cerca</span>
                <button onClick={() => { setSize('lg'); toggleModal(true); }} className="search-link rounded-icon" aria-label="Cerca" title="Cerca">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon" role="img">
                        <path d="M21.9 21.1L16 15.3a8.3 8.3 0 002-5.3 8 8 0 10-8 8 8.3 8.3 0 005.3-2l5.8 5.9zM10 17a7 7 0 117-7 7 7 0 01-7 7z"></path>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                </button>
            </div>
        </div>
        <Modal isOpen={isOpen} toggle={() => toggleModal(!isOpen)} size={size} labelledBy='modalSearch' className="search-modal">
            <Container>
                <ModalHeader toggle={() => toggleModal(!isOpen)} id='modalSearch'>
                    <span className="search-modal-title"><Icon icon="it-arrow-left-circle" onClick={() => toggleModal(!isOpen)} /> Cerca</span>
                </ModalHeader>
                <ModalBody className="search-modal-body">
                <div className='form-group search-header-form mb-3'>
                    <div className='input-group'>
                        <div className='input-group-prepend'>
                            <div className='input-group-text'>
                                <Icon icon='it-search' aria-hidden size="lg" className="search-header-icon-prepend" />
                            </div>
                        </div>
                        <label htmlFor='input-group-search-header' className={isFocused ? 'search-header-label active' : 'search-header-label'}>Cerca nel sito</label>
                        <input type='text' className={isFocused ? 'form-control focus--mouse' : 'form-control'} onFocus={toggleFocusLabel} onBlur={toggleBlurLabel} onChange={(e) => setSearchKey(e.target.value)} id='input-group-search-header' name='input-group-search-header' />
                        <div className='input-group-append search-input-group-append'>
                            <button onClick={() => { toSearch(); toggleModal(!isOpen) }}  className='btn btn-primary btn-lg px-5' type='button' id='button-search-home'>Cerca</button>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Container>
        </Modal>
      </div>
    );
}

const SlimHeader = () => {

    const auth = useContext(ActaAuthContext);
    const organization = auth.organization;
    const websiteSettings = auth.websiteSettings;

    const [topBarMenu, setTopBarMenu] = useState();

    const sportellodigitale_config = organization.active_licenses?.services?.sportellodigitale;
    const auth_config = organization.active_licenses?.services?.auth;
    const actagov_config = organization.active_licenses?.services?.actagov;
    const opendata_config = organization.active_licenses?.services?.opendata;
    const assistenza_config = organization.active_licenses?.services?.assistenza;
    const calcoloimu_config = organization.active_licenses?.services?.calcoloimu;
    const areariservata_config = organization.active_licenses?.services?.areariservata;

    const user = auth.user;

    const handleLogout = () => {
        if(auth_config?.spid_login) {
            ActaAuthUtils.logout(auth_config);
        } else {
            try {
                ApiService.logout();
                window.location.replace('/');
            } catch(err) {
                console.log(err);
            }
        }        
    }
    
    const handleLogin = () => {
        ActaAuthUtils.login(auth_config);
    }

    const getTopBarMenu = () => {
        if(!websiteSettings) return;
        setTopBarMenu(new MenuClass(websiteSettings.topbar_menu_data || ''));
    }  
    
    useEffect(() => {
        getTopBarMenu();
    }, [websiteSettings]);

    if(auth.isLoading) return <div></div>;
    return(
        <Header theme="" type="slim">
            <HeaderContent>
                <HeaderBrand href={organization.region_link || '#'} target="_blank">{organization.region || 'Ente d\'appartenenza'}</HeaderBrand>                          
                <HeaderRightZone>   
                {!websiteSettings.embedded_header_url && 
                    <div className="topbar-menu-wrapper me-5">
                        <LinkList className="topbar-menu">
                            {topBarMenu && topBarMenu.content?.items?.map((navItem, i) => {
                                return (
                                    <LinkListItem key={"key_topBarItem_" + i} href={navItem.url} className="topbar-menu-item" target={navItem.blank ? '_blank' : '_self'} rel={navItem.blank ? 'noreferrer' : ''}>
                                        {navItem.name}
                                    </LinkListItem>                                    
                                )
                            })}                            
                        </LinkList>  
                    </div>
                }
                    <div className="it-access-top">
                        {user && user.isLoggedIn 
                            ? <UncontrolledDropdown nav tag="div">
                                <DropdownToggle caret nav className="logged-user-wrapper">
                                    { user.first_name + ' ' + user.last_name }
                                    <Icon className="d-none d-lg-block" color="icon-white" icon="it-user" />
                                    <Icon className="d-none d-lg-block" color="icon-white" icon="it-expand" />
                                </DropdownToggle>
                                <DropdownMenu style={{ width: 300, zIndex: 9999 }} className="mt-3">
                                <Row>
                                    <Col className="px-4" size="12">
                                        <LinkList>
                                            {user.hasPermissions() ? (
                                                <>
                                                    <LinkListItem header className="logged-user-menu-header mb-2 pt-2 fw-bolder" >
                                                        MENU OPERATORE
                                                    </LinkListItem> 
                                                    <LinkListItem className="logged-user-menu-link" href={auth_config?.frontend_url}>
                                                        Dashboard Operatore
                                                    </LinkListItem>
                                                </>
                                            ) : (
                                                <>
                                                    <LinkListItem header className="logged-user-menu-header mb-2 pt-2 fw-bolder" >
                                                        MENU
                                                    </LinkListItem>
                                                </>
                                            )}
                                            { (user.hasPermissions() || user.role != user.selectedRole) && (
                                                <>
                                                    <LinkListItem className="logged-user-menu-link" href="#" onClick={(e) => { e.preventDefault(); auth.unsetSelectedRole(); }}>
                                                        Modifica ruolo
                                                    </LinkListItem>
                                                </>
                                            )}

                                            {/***** ACTAGOV ******/}
                                            {actagov_config && actagov_config.active && (
                                                <>
                                                    {user.hasPermissions() ? (
                                                        <>
                                                            <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                Sito Web
                                                            </LinkListItem> 
                                                            <LinkListItem className="logged-user-menu-link" href={actagov_config?.frontend_url + "admin"}>
                                                                Dashboard
                                                            </LinkListItem>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                                    
                                            {/***** OPEN DATA ******/}
                                            {opendata_config && opendata_config.active && (
                                                <> 
                                                    {user.hasPermissions() && !user.isEditor2() && !user.isEditor1() ? (
                                                        <>
                                                            <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                Portale Open Data
                                                            </LinkListItem>
                                                            <LinkListItem className="logged-user-menu-link" href={opendata_config.frontend_url + "admin"} >
                                                                Dashboard
                                                            </LinkListItem>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}

                                            {/***** SPORTELLO  ******/}
                                            {sportellodigitale_config && sportellodigitale_config.active&& (
                                                <>
                                                    {user.hasPermissions() ? (
                                                        !user.isEditor2() && !user.isEditor1() 
                                                            ? <>
                                                                    <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                        Sportello digitale
                                                                    </LinkListItem> 
                                                                    <LinkListItem className="logged-user-menu-link" href={sportellodigitale_config.frontend_url + "manage/requests/"} >
                                                                        Pratiche ricevute
                                                                    </LinkListItem>
                                                                    <LinkListItem className="logged-user-menu-link" href={sportellodigitale_config.frontend_url + "manage/requests/waiting-protocol"}>
                                                                        Pratiche da protocollare
                                                                    </LinkListItem>
                                                                </>
                                                            : ''                                                        
                                                    ) : (
                                                        <>
                                                            <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                Sportello digitale
                                                            </LinkListItem> 
                                                            <LinkListItem className="logged-user-menu-link" href={sportellodigitale_config.frontend_url + "requests/"}>
                                                                Pratiche inviate
                                                            </LinkListItem>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {/***** ASSISTENZA ******/}
                                            {assistenza_config && assistenza_config.active && (
                                                <> 
                                                    {user.hasPermissions() && !user.isEditor2() ? (
                                                        <>
                                                            <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                Portale Assistenza
                                                            </LinkListItem>
                                                            <LinkListItem className="logged-user-menu-link" href={assistenza_config.frontend_url + "admin"} >
                                                                Dashboard
                                                            </LinkListItem>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}

                                            {/***** CALCOLO IMU ******/}
                                            {calcoloimu_config && calcoloimu_config.active && (
                                                <> 
                                                    {user.hasPermissions() && !user.isEditor2() ? (
                                                        <>
                                                            <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                Portale Calcolo IMU
                                                            </LinkListItem>
                                                            <LinkListItem className="logged-user-menu-link" href={calcoloimu_config.frontend_url + "admin"} >
                                                                Dashboard
                                                            </LinkListItem>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}

                                            {/***** AREA RISERVATA ******/}
                                            {areariservata_config && areariservata_config.active && (
                                                <> 
                                                    {user.hasPermissions() ? (
                                                        <>
                                                            <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                Area Riservata
                                                            </LinkListItem>
                                                            <LinkListItem className="logged-user-menu-link" href={areariservata_config.frontend_url + "admin"} >
                                                                Dashboard
                                                            </LinkListItem>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <LinkListItem header className="logged-user-menu-header mb-2 pt-4 fw-bolder" >
                                                                Area Riservata
                                                            </LinkListItem>
                                                            <LinkListItem className="logged-user-menu-link" href={areariservata_config.frontend_url} >
                                                                Area Riservata
                                                            </LinkListItem>
                                                        </>
                                                    )}
                                                </>
                                            )}


                                            <a href="#" onClick={handleLogout}>
                                                <Button className="btn btn-sm btn-primary mt-4" block>
                                                    ESCI
                                                </Button>
                                            </a>
                                        </LinkList>
                                    </Col>
                                </Row>
                                </DropdownMenu>
                              </UncontrolledDropdown>
        
                            : <div><Icon icon="it-user" className="rounded-icon" /> <a href="#" onClick={handleLogin} size="sm" data-element="personal-area-login">Accedi all'area personale</a></div>}
                        
                    </div>
                </HeaderRightZone>
            </HeaderContent>
        </Header>
    );
}

const CenterHeader = () => {

    const auth = useContext(ActaAuthContext);

    const organization = auth.organization;   

    const auth_config = organization.active_licenses?.services?.auth; 
    const actagov_config = organization.active_licenses?.services?.actagov;

    if(auth.isLoading) return "";
    return(
        <Header theme="" type="center">
            <HeaderContent>
                <HeaderBrand href={actagov_config?.frontend_url}>
                    <Row>
                        <Col className="col-organization-logo g-0">
                            {organization.logo
                            ? <img src={auth_config?.backend_url + organization.logo} alt={organization.name} title={organization.name} width='82' height='82' />
                            : <Icon icon="it-code-circle" />}
                        </Col>
                        <Col className="col-organization-name g-0">
                            <h2>{organization.name || 'Comune Alfa'}</h2>
                            <h3>{organization.subname || 'Uno dei tanti comuni'}</h3>
                        </Col>
                    </Row>
                </HeaderBrand>
                <HeaderRightZone>
                    <HeaderSocialsZone label="Seguici su">
                        <ul>                            
                        {organization.social && organization.social.map((social)=>
                            <li key={social.name}>
                                <a aria-label={social.name} href={social.link} target="_blank"><Icon icon={social.icon} /></a>                                
                            </li>
                        )}
                        </ul>
                    </HeaderSocialsZone>
                    <SearchModal />
                </HeaderRightZone>
            </HeaderContent>
        </Header>
    );
}

const NavHeader = () => {

    const auth = useContext(ActaAuthContext);

    const organization = auth.organization;
    const websiteSettings = auth.websiteSettings;
    
    const auth_config = organization.active_licenses?.services?.auth;
    const actagov_config = organization.active_licenses?.services?.actagov;

    const [isOpen, toggleModal] = useState(false);

    const [stickyTags, setStickyTags] = useState();
    const [navMenu, setNavMenu] = useState();

    const fetchTags = async() => {
        const tagsList = await authApiService.getTags();
        const stickyTags = tagsList.filter(tag => tag.is_sticky === true);
        setStickyTags(stickyTags);
    }

    useEffect(() => {
        fetchTags();
    }, [])

    const getNavMenu = () => {
        if(!websiteSettings) return;
        setNavMenu(new MenuClass(websiteSettings.navbar_menu_data));
    }  
    
    useEffect(() => {
        getNavMenu();
    }, [websiteSettings]); 

    return(
        <Header theme="" type="navbar">
            <HeaderContent expand="lg" megamenu className="ps-3">
                <HeaderToggler aria-controls="nav1" aria-expanded="false" aria-label="Toggle navigation" onClick={() => toggleModal(!isOpen)}>
                    <Icon icon="it-burger" />
                    <Modal isOpen={isOpen} toggle={() => toggleModal(!isOpen)} align='left' scrollable labelledBy='mobile-navmenu' className="mobile-navmenu-sidebar">
                        <ModalHeader toggle={() => toggleModal(!isOpen)} id='mobile-navmenu'>
                            <div className="mobile-navmenu-logo-wrapper">
                                <div className="mobile-navmenu-logo">
                                    <a href="/" title={organization.name}>
                                    {organization.logo
                                        ? <img src={auth_config?.backend_url + organization.logo} alt={organization.name} title={organization.name} width='80' />
                                        : <Icon icon="it-code-circle" />}
                                    </a>
                                </div>
                                <div className="mobile-navmenu-title"><a href="/" title={organization.name}>{organization.name}</a></div>
                            </div>                           
                        </ModalHeader>
                        <ModalBody>
                            <Nav navbar data-element="main-navigation">
                                {navMenu && navMenu.content.items.map((navItem) => {
                                    return (
                                        <NavItem key={"key_mobileNavItem_" + navItem.name}>
                                            <NavLink href={navItem.url} className="mobile-navmenu-item" data-element={navItem.name === 'Servizi' ? 'all-services' : ''} target={navItem.blank ? '_blank' : '_self'} rel={navItem.blank ? 'noreferrer' : ''}><strong>{navItem.name}</strong></NavLink>
                                        </NavItem>                                    
                                    )
                                })}                            
                            </Nav>
                            <Nav navbar secondary className="mt-4">
                                {stickyTags && stickyTags.slice(0,2).map((tag) => {
                                    return (
                                    <NavItem key={"key_tagLink_" + tag.slug}>
                                        <NavLink href={actagov_config?.frontend_url + 'argomenti/' + tag.slug + '/'}>{tag.name}</NavLink>
                                    </NavItem>
                                    )
                                })}
                                <NavItem key="key_tagLink_all-tags">
                                    <NavLink href={actagov_config?.frontend_url + 'argomenti/'} data-element="all-topics"><strong>Tutti gli argomenti...</strong></NavLink>
                                </NavItem>
                            </Nav>
                            <Row className="mt-5">
                                <Col>
                                    <span className="mobile-follow-us">Seguici Su</span>
                                    <ul className="mobile-follow-us-list">                            
                                    {organization.social && organization.social.map((social)=>
                                        <li key={social.name} style={{ display: "inline-block" }}>
                                            <a aria-label={social.name} href={social.link} target="_blank"><Icon icon={social.icon} /></a>                                
                                        </li>
                                    )}
                                    </ul>
                                </Col>
                                
                            </Row>
                        </ModalBody>
                    </Modal>
                </HeaderToggler>
                <Collapse header navbar onOverlayClick={function noRefCheck(){}}>
                    <div className="menu-wrapper">
                        <Nav navbar data-element="main-navigation">
                            {navMenu && navMenu.content.items.map((navItem) => {
                                let navItemDataElementAttribute;
                                switch(navItem.name) {

                                    case "Amministrazione":
                                        navItemDataElementAttribute = 'management';
                                        break;

                                    case "Novità":
                                    case "News":
                                        navItemDataElementAttribute = 'news';
                                        break;

                                    case "Servizi":
                                        navItemDataElementAttribute = 'all-services';
                                        break;

                                    case "Vivere il Comune":
                                        navItemDataElementAttribute = 'live';
                                        break;

                                    default:
                                        navItemDataElementAttribute = 'custom-submenu';
                                }
                                return (
                                    <NavItem key={navItem.name}>
                                        <NavLink href={navItem.url} data-element={navItemDataElementAttribute} target={navItem.blank ? '_blank' : '_self'} rel={navItem.blank ? 'noreferrer' : ''}>{navItem.name}</NavLink>
                                    </NavItem>                                    
                                )
                            })}                            
                        </Nav>
                        <Nav navbar secondary>
                            {stickyTags && stickyTags.slice(0,2).map((tag) => {
                                return (
                                <NavItem key={"key_tagLink_" + tag.slug}>
                                    <NavLink href={actagov_config?.frontend_url + 'argomenti/' + tag.slug + '/'}>{tag.name}</NavLink>
                                </NavItem>
                                )
                            })}
                            <NavItem key="key_tagLink_all-tags">
                                <NavLink href={actagov_config?.frontend_url + 'argomenti/'} data-element="all-topics"><strong>Tutti gli argomenti...</strong></NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </Collapse>
            </HeaderContent>
        </Header>
    );
}

const EmbeddedTopHeader = () => {
    const auth = useContext(ActaAuthContext);
    const websiteSettings = auth.websiteSettings;

    const [htmlContent, setHtmlContent] = useState('');
    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    const fetchData = async() => {
        try {
            const html = await authApiService.getEmbeddedHeader(websiteSettings.embedded_header_url);

            // Create a temporary DOM element to parse the HTML
            const tempElement = document.createElement('div');
            tempElement.innerHTML = html;

            // Remove link, style, and script tags
            const tagsToRemove = ['link', 'style', 'scripts'];
            tagsToRemove.forEach(tagName => {
                const tags = tempElement.querySelectorAll(tagName);
                tags.forEach(tag => tag.remove());
            });

            // Set the modified HTML content
            setHtmlContent(tempElement.innerHTML);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const loadScripts = async() => {
        try {
            const html = await authApiService.getEmbeddedHeader(websiteSettings.embedded_header_url);

            // Create a temporary DOM element to parse the HTML
            const tempElement = document.createElement('div');
            tempElement.innerHTML = html;

            // Find script tags
            const scriptTags = tempElement.querySelectorAll('script');

            // Load scripts and wait for them to complete
            const loadPromises = Array.from(scriptTags).map(async scriptTag => {
                const scriptSrc = scriptTag.getAttribute('src');
                if (scriptSrc) {
                    return new Promise(resolve => {
                        const script = document.createElement('script');
                        script.src = scriptSrc;
                        script.onload = resolve;
                        document.body.appendChild(script);
                    });
                }
            });

            await Promise.all(loadPromises);
            setScriptsLoaded(true);
        } catch (error) {
            console.error('Error loading scripts:', error);
        }
    }

    let loadScriptsFlag = false;

    useEffect(() => {
        fetchData();
        if(!loadScriptsFlag) {
            loadScriptsFlag = true;
            loadScripts();
        }
    }, []);

    return scriptsLoaded ? <div className="embedded-header-wrapper" dangerouslySetInnerHTML={{ __html: htmlContent }} /> : '';

}

const TopHeader = () => {

    const auth = useContext(ActaAuthContext);
    const websiteSettings = auth.websiteSettings;

    if (websiteSettings.embedded_header_url){
        return <>
            {websiteSettings.show_top_header && (
                 <SlimHeader />
            )}
            <EmbeddedTopHeader />
        </>;
    } 

    return <>
        <Headers>
            {websiteSettings.show_top_header && (
                 <SlimHeader />
            )}
            <div className="it-nav-wrapper">
                <CenterHeader />
                <NavHeader  />
            </div>
        </Headers>        
    </>;
}

export default TopHeader;